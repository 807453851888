import { createRouter, createWebHistory } from "vue-router";
import { staticRoutes } from "@/router/routes";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import token from "@/utils/token";

const routes = staticRoutes;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (to.meta.title) {
    document.title = process.env.VUE_APP_NAME + " - " + to.meta.title;
  }
  if (to.path === "/admin/login") {
    token.set("");
    next();
  } else {
    if (token.get()) {
      next();
    } else {
      next("/admin/login");
    }
  }
  NProgress.done();
});

export default router;
