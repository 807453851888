import { createStore } from "vuex";

export default createStore({
  state: {
    UserRouter: []
  },
  getters: {},
  mutations: {
    setUserRouter(state, value) {
      state.UserRouter = value;
    }
  },
  actions: {},
  modules: {}
});
