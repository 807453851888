import { createApp } from "vue";
import "./assets/styles/fonts.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import App from "./App.vue";
import router from "./router";
import store from "./store";

require("./utils/interceptor");

const app = createApp(App);
for (const [name, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(name, component);
}

app.use(ElementPlus, { locale: zhCn }).use(store).use(router).mount("#app");
