export const staticRoutes = [
  {
    path: "/",
    meta: {
      title: "主页"
    },
    component: () => import("@/views/home"),
    children: [
      {
        path: "/preview",
        meta: {
          id: 100,
          title: "首页",
          icon: {
            default: require("@/assets/home/menu/home_d.png"),
            activate: require("@/assets/home/menu/home_a.png")
          }
        },
        component: () => import("@/views/preview")
      },
      {
        path: "/goods",
        meta: {
          id: 200,
          title: "商品",
          icon: {
            default: require("@/assets/home/menu/goods_d.png"),
            activate: require("@/assets/home/menu/goods_a.png")
          }
        },
        component: () => import("@/views/goods"),
        children: [
          {
            path: "/goods/list",
            meta: {
              id: 201,
              title: "全部商品"
            },
            component: () => import("@/views/goods/list")
          },
          {
            path: "/goods/create",
            meta: {
              id: 202,
              title: "发布商品"
            },
            component: () => import("@/views/goods/create")
          },
          {
            path: "/goods/param",
            meta: {
              id: 203,
              title: "属性管理"
            },
            component: () => import("@/views/goods/param")
          },
          {
            path: "/goods/photo",
            meta: {
              id: 204,
              title: "图片空间"
            },
            component: () => import("@/views/goods/photo")
          }
        ]
      },
      {
        path: "/advertise",
        meta: {
          id: 300,
          title: "广告",
          icon: {
            default: require("@/assets/home/menu/advertise_d.png"),
            activate: require("@/assets/home/menu/advertise_a.png")
          }
        },
        component: () => import("@/views/advertise"),
        children: [
          {
            path: "/advertise/list",
            meta: {
              id: 301,
              title: "广告设置"
            },
            component: () => import("@/views/advertise/list")
          }
        ]
      },
      {
        path: "/deal",
        meta: {
          id: 400,
          title: "交易",
          icon: {
            default: require("@/assets/home/menu/deal_d.png"),
            activate: require("@/assets/home/menu/deal_a.png")
          }
        },
        component: () => import("@/views/deal"),
        children: [
          {
            path: "/deal/list",
            meta: {
              id: 401,
              title: "交易详情"
            },
            component: () => import("@/views/deal/list")
          }
        ]
      },
      {
        path: "/customer",
        meta: {
          id: 500,
          title: "客户",
          icon: {
            default: require("@/assets/home/menu/customer_d.png"),
            activate: require("@/assets/home/menu/customer_a.png")
          }
        },
        component: () => import("@/views/customer"),
        children: [
          {
            path: "/customer/list",
            meta: {
              id: 501,
              title: "客户列表"
            },
            component: () => import("@/views/customer/list")
          }
        ]
      },
      {
        path: "/seting",
        meta: {
          id: 600,
          title: "系统",
          icon: {
            default: require("@/assets/home/menu/seting_d.png"),
            activate: require("@/assets/home/menu/seting_a.png")
          }
        },
        component: () => import("@/views/seting"),
        children: [
          {
            path: "/seting/account",
            meta: {
              id: 601,
              title: "账号管理"
            },
            component: () => import("@/views/seting/account")
          }
        ]
      }
    ]
  },
  {
    path: "/admin/login",
    meta: {
      title: "登录"
    },
    component: () => import("@/views/admin/login")
  },
  {
    path: "/:pathMatch(.*)*",
    meta: {
      title: "404"
    },
    component: () => import("@/views/404/index.vue")
  }
];
