function set(token) {
  localStorage.setItem("token", token);
}

function get() {
  let token = localStorage.getItem("token");
  return token ? token : "";
}

export default {
  set,
  get
};
